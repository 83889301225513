<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>优惠券</el-breadcrumb-item>
      <el-breadcrumb-item>发放优惠券</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-if="isShowData">
      <!-- 查询 -->
      <div
        style="
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        "
      >
        <label style="margin: 10px" for=""
          >停车场：
          <el-select
            v-model="query.pkId"
            filterable
            placeholder="请选择"
            style="width: 150px"
            size="small"
            @change="pkIdChange"
          >
            <el-option
              v-for="item in PkInfoSelect"
              :key="item.id"
              :label="item.pkName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </label>
        <label style="margin: 10px" for=""
          >领取车牌：
          <el-input
            style="width: 150px"
            size="small"
            placeholder="请输入领取车牌"
            v-model="query.plateNo"
            clearable
          />
        </label>
        <label style="margin: 10px" for=""
          >商家名称：
          <el-select
            v-model="query.shopId"
            filterable
            placeholder="请输入商家名称"
            style="width: 150px"
            size="small"
            clearable
          >
            <el-option
              v-for="item in businessNameSelect"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <!-- <el-input
            style="width: 150px"
            size="small"
            placeholder="请输入商家名称"
            v-model="query.shopName"
            clearable
          /> -->
        </label>
        <label style="margin: 10px" for=""
          >姓名：
          <el-input
            style="width: 150px"
            size="small"
            placeholder="请输入姓名"
            v-model="query.userName"
            clearable
          />
        </label>
        <label style="margin: 10px" for=""
          >手机号：
          <el-input
            style="width: 150px"
            size="small"
            placeholder="请输入手机号"
            v-model="query.mobile"
            clearable
          />
        </label>
        <label style="margin: 10px" for=""
          >是否使用：
          <el-select
            v-model="query.isUsed"
            clearable
            placeholder="请选择"
            style="width: 120px"
            size="small"
          >
            <el-option label="否" :value="0"></el-option>
            <el-option label="是" :value="1"></el-option>
          </el-select>
        </label>
        <label style="margin: 5px" for=""
          >发放时间：<el-date-picker
            v-model="createBeginTime"
            @change="createBeginTimechange"
            type="datetime"
            value-format="timestamp"
            size="small"
            placeholder="选择日期时间"
            style="width: 200px"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="createEndTime"
            @change="createEndTimechange"
            type="datetime"
            value-format="timestamp"
            size="small"
            default-time="23:59:59"
            placeholder="选择日期时间"
            style="width: 200px"
          >
          </el-date-picker
        ></label>
        <label style="margin: 5px" for=""
          >过期时间：<el-date-picker
            style="width: 200px"
            v-model="overBeginTime"
            @change="overBeginTimechange"
            type="datetime"
            value-format="timestamp"
            size="small"
            placeholder="选择日期时间"
          >
          </el-date-picker>
          至
          <el-date-picker
            style="width: 200px"
            v-model="overEndTime"
            @change="overEndTimechange"
            type="datetime"
            value-format="timestamp"
            size="small"
            default-time="23:59:59"
            placeholder="选择日期时间"
          >
          </el-date-picker
        ></label>
        <label style="margin: 5px" for=""
          >使用时间：<el-date-picker
            style="width: 200px"
            v-model="userBeginTime"
            @change="userBeginTimechange"
            type="datetime"
            value-format="timestamp"
            size="small"
            placeholder="选择日期时间"
          >
          </el-date-picker>
          至
          <el-date-picker
            style="width: 200px"
            v-model="userEndTime"
            @change="userEndTimechange"
            type="datetime"
            value-format="timestamp"
            size="small"
            default-time="23:59:59"
            placeholder="选择日期时间"
          >
          </el-date-picker
        ></label>
        <el-button
          style="margin-left: 10px"
          type="primary"
          size="small"
          @click="userCouponcx"
          >查询</el-button
        >
        <el-button size="small" @click="userCouponcz()">重置</el-button>
      </div>
      <!-- 点击添加显示对话框 -->
      <el-button
        type="primary"
        size="small "
        icon="el-icon-plus"
        @click="isShowAdd = true"
        v-if="$cookies.get('user')"
        >发放优惠券</el-button
      >
      <el-button
        type="primary"
        size="small "
        icon="el-icon-plus"
        :loading="isShowImprot"
        @click="improtClick"
        >导出</el-button
      >
      <hr />
      <!-- 表格 -->
      <transition appear tag="div">
        <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          stripe
          show-summary
          :summary-method="getcouponsTable"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            header-align="left"
            align="left"
            prop="id"
            label="编号"
            width="60"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="plateNo"
            label="领取车牌"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="trueName"
            label="姓名"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="mobile"
            label="手机号"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.userInfo.mobile }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="shopName"
            label="商家"
            v-if="!$cookies.get('user')"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.shopInfo.shopName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="couponName"
            label="优惠券名称"
            width="100"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="couponMoney"
            label="优惠金额"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="deductionMoney"
            label="实际抵扣金额"
          >
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="createTime"
            label="发放/领取时间"
            width="150"
          >
            <template slot-scope="scope">
              <span>{{ conversionTime(scope.row.createTime) }}</span>
            </template></el-table-column
          >
          <el-table-column
            header-align="left"
            align="left"
            prop="endTime"
            label="到期时间"
            width="150"
          >
            <template slot-scope="scope">
              <span>{{ conversionTime(scope.row.endTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="usePlateNo"
            label="使用车牌"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="usedTime"
            label="使用时间"
            width="150"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.usedTime
                  ? conversionTime(scope.row.usedTime)
                  : '未使用'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="orderNo"
            label="订单号"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="remarks"
            label="备注"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            label="操作"
            width="150"
            fixed="right"
          >
            <template
              slot-scope="scope"
              v-if="
                $cookies.get('user') && !scope.row.usedTime && scope.row.plateNo
              "
            >
              <el-button
                type="text"
                @click="editClickIsShow(scope)"
                size="mini"
                icon="el-icon-share"
                >编辑</el-button
              >
              <el-button
                type="text"
                @click="delClick(scope)"
                size="mini"
                icon="el-icon-delete"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </transition>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.current"
        :page-sizes="[10, 50, 100]"
        :page-size="query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加 -->
    <el-dialog
      title="发放优惠券"
      :visible.sync="isShowAdd"
      @close="addClose"
      width="50%"
      v-el-drag-dialog
    >
      <el-form
        :model="addForm"
        :rules="formRules"
        ref="addRef"
        label-width="140px"
        class="demo-ruleForm"
      >
        <el-form-item label="车牌号码" prop="plateNo">
          <el-input
            v-model="addForm.plateNo"
            placeholder="请输入车牌号码"
            @change="plateinfo"
          >
            <template slot="prepend">
              <LicenseKeyboard v-model="addForm.plateNo" title="软键盘">
              </LicenseKeyboard>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <div
            v-if="platenoInfo.outTime && platenoInfo.inTime"
            class="platenoInfos"
          >
            该车停时长：{{
              timeFn(platenoInfo.inTime, platenoInfo.outTime)
            }}
            应付停车费：{{ platenoInfo.needPayMoney }} 元
          </div>
        </el-form-item>
        <el-form-item label="选择优惠券" prop="couponId">
          <el-select
            v-model="addForm.couponId"
            placeholder="请选择"
            @click.native="getElectronicCoupon"
          >
            <el-option
              v-for="item in CouponList"
              :key="item.id"
              :label="item.couponName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否同意自动抵扣" prop="isAgreeAutoUsed">
          <el-radio-group v-model="addForm.isAgreeAutoUsed">
            <el-radio :label="0">不同意自动抵扣</el-radio>
            <el-radio :label="1">同意自动抵扣</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input
            type="textarea"
            v-model="addForm.remarks"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <div style="padding-left: 100px">
        <p>
          说明：该车牌号码下绑定优惠券后，离场时如优惠券金额足够支付订单金额，自动开闸放行
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowAdd = false">取 消</el-button>
        <el-button type="primary" @click="addClick">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog
      title="发放优惠券"
      :visible.sync="isShowEdit"
      width="50%"
      v-el-drag-dialog
    >
      <el-form
        :model="editForm"
        :rules="formRules"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="车牌号码" prop="plateNo">
          <el-input
            v-model="editForm.plateNo"
            placeholder="请输入车牌号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="选择优惠券" prop="couponId">
          <el-select
            v-model="editForm.couponId"
            placeholder="请选择"
            @click.native="getElectronicCoupon"
          >
            <el-option
              v-for="item in CouponList"
              :key="item.id"
              :label="item.couponName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否同意自动抵扣" prop="isAgreeAutoUsed">
          <el-radio-group v-model="editForm.isAgreeAutoUsed">
            <el-radio :label="0">不同意自动抵扣</el-radio>
            <el-radio :label="1">同意自动抵扣</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input
            type="textarea"
            v-model="editForm.remarks"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <div style="padding-left: 100px">
        <p>
          说明：该车牌号码下绑定优惠券后，离场时如优惠券金额足够支付订单金额，自动开闸放行
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowEdit = false">取 消</el-button>
        <el-button type="primary" @click="editClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    userCoupon,
    exportUserCoupon,
    adduserCoupon,
    getElectronicCoupon,
    userCouponId,
  } from '@/api/coupon.js';
  import { findPkInfoSelect, businessList } from '@/api/Parkinglot.js';
  import { request } from '@/network/request.js';
  export default {
    data() {
      return {
        isShowImprot: false,
        loading: false,
        isShowData: false,
        isShowAdd: false,
        isShowEdit: false,
        tableData: [],
        businessNameSelect: [],
        createBeginTime: undefined, // 发放进场时间起始
        createEndTime: undefined, // 发放结束时间截至
        overBeginTime: undefined, // 过期进场时间起始
        overEndTime: undefined, // 过期结束时间截至
        userBeginTime: undefined, // 使用进场时间起始
        userEndTime: undefined, // 使用结束时间截至
        query: {
          // 查询条件
          current: 1,
          size: 10,
          pkId: undefined, // 停车场id
          shopId: undefined, // 商户名称
          shopName: undefined, // 商户名称
          userName: undefined, // 用户名
          plateNo: undefined,
          isUsed: undefined,
          mobile: undefined,
          createBeginTime: undefined, // 发放进场时间起始
          createEndTime: undefined, // 发放结束时间截至
          overBeginTime: undefined, // 过期进场时间起始
          overEndTime: undefined, // 过期结束时间截至
          userBeginTime: undefined, // 使用进场时间起始
          userEndTime: undefined, // 使用结束时间截至
        },
        addForm: {
          id: undefined,
          couponId: undefined,
          plateNo: undefined,
          isAgreeAutoUsed: 1,
          CreateTime: undefined,
        },
        editForm: {
          id: undefined,
          couponId: undefined,
          plateNo: undefined,
          isAgreeAutoUsed: 1,
        },
        formRules: {
          // 添加表单的验证规则
          plateNo: [
            { required: true, message: '请输入车牌号码', trigger: 'blur' },
            {
              min: 1,
              max: 99,
              message: '长度在 1 到 99 个字符',
              trigger: 'blur',
            },
          ],
          couponId: [
            { required: true, message: '请选择优惠券', trigger: 'change' },
          ],
          // remarks: [
          //   { required: true, message: '请输入备注', trigger: 'blur' },
          //   {
          //     min: 1,
          //     max: 99,
          //     message: '长度在 1 到 99 个字符',
          //     trigger: 'blur',
          //   },
          // ],
        },
        improtquery: {
          pkId: undefined, // 停车场id
          shopName: undefined, // 商户名称
          userName: undefined, // 用户名
          plateNo: undefined,
          isUsed: undefined,
          mobile: undefined,
          createBeginTime: undefined, // 发放进场时间起始
          createEndTime: undefined, // 发放结束时间截至
          overBeginTime: undefined, // 过期进场时间起始
          overEndTime: undefined, // 过期结束时间截至
          userBeginTime: undefined, // 使用进场时间起始
          userEndTime: undefined, // 使用结束时间截至
        },
        total: undefined,
        PkInfoSelect: [], // 保存停车场下拉框
        CouponList: [],
        platenoInfo: {
          needPayMoney: 0, //应付金额
          inTime: undefined, //进场时间
          outTime: undefined, //出场时间
        },
      };
    },
    created() {
      this.findPkInfoSelect();
    },
    methods: {
      // table汇总显示
      getcouponsTable(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '汇总';
            return;
          }
          if (
            column.property == 'deductionMoney' ||
            column.property == 'couponMoney'
          ) {
            const values = data.map((item) => Number(item[column.property]));
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += ' 元';
            }
          }
        });

        return sums;
      },
      //停车场选择
      pkIdChange() {
        this.businessList();
      },
      // 商家列表
      businessList() {
        businessList({ pkId: this.query.pkId }).then((res) => {
          this.businessNameSelect = res.data.data.map((el) => {
            return { name: el.shopName, id: el.id };
          });
        });
      },
      // 发放开始时间
      createBeginTimechange() {},
      // 发放结束时间
      createEndTimechange() {},
      // 过期开始时间
      overBeginTimechange() {},
      // 过期结束时间选择
      overEndTimechange() {
        // if (this.startTime === null || this.startTime === undefined) {
        //   this.endTime = null
        //   return this.$message.warning('请选择开始时间')
        // } else if (this.endTime < this.startTime) {
        //   this.endTime = this.startTime + 3600 * 1000 * 24 * 1
        //   return this.$message.warning('不可选择小于开始时间的日期')
        // }
      },
      // 使用开始时间
      userBeginTimechange() {},
      // 使用结束时间
      userEndTimechange() {},
      plateinfo(item) {
        this.$axios({
          method: 'get',
          url:
            process.env.VUE_APP_BASE_API +
            'parking/saas/h5Api/orderInfo/findPayInfo',
          headers: { pk_id: this.query.pkId },
          params: {
            plateNo: item,
          },
        }).then((res) => {
          if (res.data.data.orderDetailList.length > 0) {
            this.platenoInfo.inTime = res.data.data.inTime;
            this.platenoInfo.outTime = res.data.data.outTime; //最新出场时间
            this.platenoInfo.needPayMoney = res.data.data.needPayMoney;
          } else {
            this.platenoInfo.inTime = undefined;
            this.platenoInfo.outTime = undefined; //最新出场时间
            this.platenoInfo.needPayMoney = 0;
          }
        });
      },
      // 计算两个时间差 dateBegin 开始时间
      timeFn(dateBegin, dateEnd) {
        //如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
        var dateDiff = dateEnd * 1000 - dateBegin * 1000; //时间差的毫秒数时间戳
        var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
        var leave1 = dateDiff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
        var hours = Math.floor(leave1 / (3600 * 1000)); //计算出小时数
        //计算相差分钟数
        var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
        var minutes = Math.floor(leave2 / (60 * 1000)); //计算相差分钟数
        //计算相差秒数
        var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
        var seconds = Math.round(leave3 / 1000);
        var leave4 = leave3 % (60 * 1000); //计算分钟数后剩余的毫秒数
        var minseconds = Math.round(leave4 / 1000);
        // var timeFn = "耗时："+dayDiff+"天 "+hours+"小时 "+minutes+" 分钟"+seconds+" 秒"+minseconds+"毫秒";
        var timeFn =
          dayDiff +
          '天 ' +
          hours +
          '小时 ' +
          minutes +
          ' 分钟' +
          seconds +
          ' 秒';
        return timeFn;
      },
      getElectronicCoupon() {
        getElectronicCoupon().then((res) => {
          if (res.data.code === '200') {
            this.CouponList = res.data.data;
          }
        });
      },
      improtClick() {
        // 导出
        this.isShowImprot = true;
        this.improtquery.pkId = this.query.pkId;
        this.improtquery.shopName = this.query.shopName;
        this.improtquery.userName = this.query.userName;
        this.improtquery.plateNo = this.query.plateNo;
        this.improtquery.isUsed = this.query.isUsed;
        this.improtquery.mobile = this.query.mobile;

        this.improtquery.createBeginTime = this.createBeginTime;
        this.improtquery.createEndTime = this.createEndTime;
        this.improtquery.overBeginTime = this.overBeginTime;
        this.improtquery.overEndTime = this.overEndTime;
        this.improtquery.userBeginTime = this.userBeginTime;
        this.improtquery.userEndTime = this.userEndTime;
        if (this.improtquery.createBeginTime != undefined) {
          this.improtquery.createBeginTime = (
            this.improtquery.createBeginTime / 1000
          ).toFixed(0);
        }
        if (this.improtquery.createEndTime != undefined) {
          this.improtquery.createEndTime = (
            this.improtquery.createEndTime / 1000
          ).toFixed(0);
        }
        if (this.improtquery.overBeginTime != undefined) {
          this.improtquery.overBeginTime = (
            this.improtquery.overBeginTime / 1000
          ).toFixed(0);
        }
        if (this.improtquery.overEndTime != undefined) {
          this.improtquery.overEndTime = (
            this.improtquery.overEndTime / 1000
          ).toFixed(0);
        }
        if (this.improtquery.userBeginTime != undefined) {
          this.improtquery.userBeginTime = (
            this.improtquery.userBeginTime / 1000
          ).toFixed(0);
        }
        if (this.improtquery.userEndTime != undefined) {
          this.improtquery.userEndTime = (
            this.improtquery.userEndTime / 1000
          ).toFixed(0);
        }

        exportUserCoupon(this.improtquery).then((res) => {
          if (res.data.code === '200') {
            window.open(res.data.data);
          }
          this.isShowImprot = false;
        });
      },
      userCoupon() {
        this.query.createBeginTime = this.createBeginTime;
        this.query.createEndTime = this.createEndTime;
        this.query.overBeginTime = this.overBeginTime;
        this.query.overEndTime = this.overEndTime;
        this.query.userBeginTime = this.userBeginTime;
        this.query.userEndTime = this.userEndTime;
        if (this.query.createBeginTime && !this.query.createEndTime) {
          return this.$message.warning('请输入发放时间截止时间');
        } else if (!this.query.createBeginTime && this.query.createEndTime) {
          return this.$message.warning('请输入发放时间开始时间');
        }

        if (this.query.overBeginTime && !this.query.overEndTime) {
          return this.$message.warning('请输入过期时间截止时间');
        } else if (!this.query.overBeginTime && this.query.overEndTime) {
          return this.$message.warning('请输入过期时间开始时间');
        }
        if (this.query.userBeginTime && !this.query.userEndTime) {
          return this.$message.warning('请输入使用时间截止时间');
        } else if (!this.query.userBeginTime && this.query.userEndTime) {
          return this.$message.warning('请输入使用时间开始时间');
        }

        if (this.query.createBeginTime != undefined) {
          this.query.createBeginTime = (
            this.query.createBeginTime / 1000
          ).toFixed(0);
        }
        if (this.query.createEndTime != undefined) {
          this.query.createEndTime = (this.query.createEndTime / 1000).toFixed(
            0
          );
        }
        if (this.query.overBeginTime != undefined) {
          this.query.overBeginTime = (this.query.overBeginTime / 1000).toFixed(
            0
          );
        }
        if (this.query.overEndTime != undefined) {
          this.query.overEndTime = (this.query.overEndTime / 1000).toFixed(0);
        }
        if (this.query.userBeginTime != undefined) {
          this.query.userBeginTime = (this.query.userBeginTime / 1000).toFixed(
            0
          );
        }
        if (this.query.userEndTime != undefined) {
          this.query.userEndTime = (this.query.userEndTime / 1000).toFixed(0);
        }

        // 列表
        userCoupon(this.query).then((res) => {
          this.tableData = res.data.data.list;
          this.total = res.data.data.total;
          this.isShowData = true;
          this.loading = false;
        });
      },
      userCouponcx() {
        // 查询
        this.query.current = 1;
        this.query.size = 10;
        this.userCoupon();
      },
      userCouponcz() {
        // 重置
        this.query.current = 1;
        this.query.size = 10;
        this.query.pkId = this.PkInfoSelect[0].id;
        this.query.shopId = undefined;
        this.query.shopName = undefined;
        this.query.userName = undefined;
        this.query.isUsed = undefined;
        this.query.plateNo = undefined;
        this.query.mobile = undefined;
        this.createBeginTime = undefined; // 发放进场时间起始
        this.createEndTime = undefined; // 发放结束时间截至
        this.overBeginTime = undefined; // 过期进场时间起始
        this.overEndTime = undefined; // 过期结束时间截至
        this.userBeginTime = undefined; // 使用进场时间起始
        this.userEndTime = undefined; // 使用结束时间截至
        this.userCoupon();
      },
      findPkInfoSelect() {
        // 停车场下拉框
        findPkInfoSelect({ allRecord: 0 }).then((res) => {
          if (res.data.code === '200') {
            this.PkInfoSelect = res.data.data;
            this.query.pkId = this.PkInfoSelect[0].id;
            this.businessList();
            this.userCoupon();
          }
        });
      },
      addClose() {
        // 监听添加用户对话框的关闭事件 清空input数据
        this.$refs.addRef.resetFields();
      },
      addClick() {
        var dates = new Date();
        var times = dates.getTime(); //时间戳
        this.addForm.createTime = (times / 1000).toFixed(0);
        // 添加
        this.$refs.addRef.validate((valid) => {
          if (!valid) return;
          adduserCoupon(this.addForm).then((res) => {
            if (res.data.code === '200') {
              this.$message.success('添加成功');
              this.isShowAdd = false;
              this.userCoupon();
            }
          });
        });
      },
      editClickIsShow(scope) {
        // 编辑显示
        this.isShowEdit = true;
        this.editForm = scope.row;
      },
      editClick() {
        // 编辑
        adduserCoupon(this.editForm).then((res) => {
          if (res.data.code === '200') {
            this.$message.success('编辑成功');
            this.isShowEdit = false;
            this.userCoupon();
          }
        });
      },
      delClick(scope) {
        // 删除
        this.$confirm('此操作将永久删除该数据, 是否继续?', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            userCouponId(scope.row.id).then((res) => {
              if (res.data.code === '200') {
                this.$message.success('删除成功');
                this.userCoupon();
              }
            });
          })
          .catch(() => {
            this.$message.info('已取消删除');
          });
      },
      handleSizeChange(val) {
        this.loading = true;
        this.query.size = val;
        this.userCoupon();
      },
      handleCurrentChange(val) {
        this.loading = true;
        this.query.current = val;
        this.userCoupon();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .platenoInfos {
    color: red;
  }
</style>
